import React from "react"

import { DocsPage, SubpageEnum } from "../components/sets/docs/DocsPage"
import { SEO } from "../components/atoms/SEO"

const Docs: React.FC = () => {
  return (
    <>
      <SEO />
      <DocsPage subpage={SubpageEnum.HOME} />
    </>
  )
}

export default Docs
